/* Home */
:root {
    --animate-duration: 2s;
}

/* h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0rem;
}

p {
    margin-bottom: 0rem;
} */

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.row {
    margin-right: auto;
    margin-left: auto;
}

.logo {
    height: 37.04825px;
    width: 117.75337px;
    display: flex;
}

.active-line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
}



.hover-pointer {
    cursor: pointer;
}

.navbar .navbar-nav .nav-item {
    margin-inline: 1rem;
    position: relative;
    font-weight: 500;
}

.navbar .navbar-nav .nav-link.active {
    border-bottom: 2px solid #136EE5;
    font-weight: 600;
    margin-bottom: -2px;
}

.navbar .navbar-nav .nav-item a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #136EE5;
    width: 0%;
    height: 2px;
    transition: all 0.5s;
    margin-bottom: -2px;
}

.navbar .navbar-nav .nav-item a:hover::after {
    width: 100%;
}




.toggle {
    margin-right: 10%;
    height: 40px;
    width: 40px;
}

.footer {
    background-color: black;
    color: #ffffff;
    margin-top: auto;
    padding-top: 180px;

}

.footer-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-links .footer-link-item {
    display: inline-block;
    margin-inline: 20px;
    margin-bottom: 10px;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
}

.footer-links .footer-link-item a {
    color: #FFF;

    /* text-align: right; */
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    /* 188.889% */
}

.footer-links .footer-link-item a:hover {
    color: #00B8D1;
}

@media (max-width: 767px) {
    .footer-links .footer-link-item {
        display: block;
        margin-inline: 0px;
        margin-bottom: 10px;
    }
}

.label {
    height: 262px;
    width: 591px;
}

.label {
    height: 37px;
    width: 569px;
    margin-top: 20px;
}

.footer-card-title {
    color: #ffffff;
}

.footer-card-text {
    /* text-align: justify; */
    color: #595c60;
}

.footer-text {
    color: #FFF;

    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.footer-soliacl-link {
    color: #ffffff;
}

.footer-soliacl-link .fa-brands:hover {
    color: #00B8D1;
}

.home-section-1-container {
    position: relative;
}

.home-section-1-image {
    height: 882px;
}

@media (max-width: 767px) {
    .home-section-1-image {
        height: auto;
    }
}

.home-section-1-text-block {
    position: absolute;
    top: 233px;
    /* color: #ffffff; */
    /* font-size: 67px; */
    /* font-weight: 800; */
    /* line-height: 87px; */
    max-width: 510px;
}

.home-section-1-text-block .title-sec-1 {
    line-height: 87px;
    font-family: "Poppins";
    font-size: 86px;
    font-style: normal;
    font-weight: 900;
    color: #FFF;
}

.home-section-1-text-block .sub-title-sec-1 {
    /* color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 29px; */

    color: #FFF;
    font-family: "Manrope";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 103.571% */
    text-transform: capitalize;
}

.home-section-1-text-block-mob {
    position: absolute;
    top: 18px;
    color: #ffffff;
    font-size: 35px;
    font-weight: 800;
    max-width: 500px;
}

.home-section-1-text-block-mob h4 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 29px;
}

/* Style special-card */
.special-card {
    cursor: pointer;
}

.special-card .profile {
    transition: 0.2s;
    width: 100%;
}

.special-card:hover .profile {
    transform: scale(1.4);
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.special-card .content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.special-card .content .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00B8D1;
    transition: 1s;
    z-index: 1;
    padding: 10px;
    color: #FFFFFF;
}

.from-left {
    top: 0;
    left: -100%;
}

.special-card:hover .content .from-left {
    left: 0%;
}

.from-bottom {
    top: 100%;
    left: 0;
    bottom: 0;
}

.special-card:hover .content .from-bottom {
    top: 0%;
    bottom: 0;
}

.from-right {
    top: 0%;
    right: -100%;
}

.special-card:hover .content .from-right {
    right: 0;
}


.special-card .content .front .des {
    position: absolute;
    bottom: 0;
    padding: 30px;
    color: #ffffff;
}

.special-card .content .front .des h1 {
    font-size: 74px;
}

.special-card .content .front .des h4 {
    color: #FFF;

    font-family: 'Heebo';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 127.273% */
    text-transform: capitalize;
}

.special-card .content .back .tem-img {
    border-radius: 100%;
}

.special-card .content .back .des {
    position: absolute;
    bottom: 0;
    padding: 30px;
    color: #ffffff;
}

.special-card .content .back .des h1 {
    color: #FFF;

    font-family: 'Heebo';
    font-size: 74px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    /* 72.973% */
    text-transform: capitalize;
}

.special-card .content .back .des h4 {
    color: #FFF;
    font-family: 'Heebo';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 127.273% */
    text-transform: capitalize;
}

.special-card .content .back .des p {
    color: #FFF;

    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    text-transform: capitalize;
}


.home-section-2-container {
    position: relative;
}

.home-section-2-container {
    padding-top: 4rem;
    padding-bottom: 4rem;

    background: linear-gradient(to right,
            #ffffff 0%,
            #ffffff 45%,
            #EDF4F4 45%,
            #EDF4F4 100%);
    height: 100%;
    width: 100%;
}


.home-section-2-container p {
    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.home-section-2-container h2 {
    color: #00009A;
    font-family: 'Heebo';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 186.667% */
    text-transform: capitalize;
}

.home-section-2-container a {
    color: #00009A !important;

    font-family: 'Heebo';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 186.667% */
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
}

.home-section-2-container h1 {
    /* color: #000000;
    font-size: 25px;
    font-weight: 400;
    line-height: 48px; */


    color: #000;
    font-family: 'Heebo';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    /* 192% */
    text-transform: capitalize;
}

.home-section-2-container h3 {
    color: #131377;
    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .home-section-2-container {
        background: #FFF;
    }

    .home-section-2-container h3 {
        color: #131377;
        font-size: 35px;
        font-weight: 800;
        line-height: 40px;
    }
}

.home-section-2-container h4 {
    color: #000;
    font-family: 'Heebo';
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 93.333% */
    text-transform: capitalize;
}




.section-2-card {
    border-color: #D6EFEF;
    transition: transform 1s;
}


.section-2-card:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background-color: rgb(101 101 101 / 10%);

}

.section-2-card-title {
    color: #000;
    font-family: 'Heebo';
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 103.704% */
    text-transform: capitalize;
}

.section-2-card-text {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}

.section-2-card .card-body {
    padding: 1.5rem;
}

.home-section-3-container {
    background-image: url('../../assets/img/section3-bg.png');
    background-size: cover;
}

.seaction-3-heading {
    margin-top: 3%;
    margin-bottom: 2%;
}

.home-section-3-card {
    aspect-ratio: 1/1.2;
}

.seaction-3-heading h4 {
    color: #FFF;

    text-align: center;
    font-family: 'Heebo';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    /* 108% */
    text-transform: capitalize;
}

.home-section-3-text-block {
    position: absolute;
    padding: 30px;
    color: #ffffff;
    font-size: 84px;
    font-weight: 800;
    /* letter-spacing: 0;*/
    line-height: 87px;
    bottom: 0;
}

.home-section-3-text-block p {
    color: #ffffff;
    font-size: 74px;
    font-weight: 800;
    line-height: 54px;
}

.home-section-3-text-block h4 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
}

.home-section-3-text-block h6 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    display: none;
}

.home-section-3-cards {
    transition: opacity 1s ease;
}

.home-section-3-cards:hover h6 {
    display: block;
    top: 0;
    left: 0;
    opacity: 1;
    text-shadow: 0px 0px 10px black;
    transition: opacity 1s ease;
}

.home-section-4-card {
    aspect-ratio: 1/1.3;
}

.section-4-card {
    border-color: #D6EFEF;
    transition: transform 1s;
}


.section-4-card:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.section-4-heading-1 {
    color: #000;

    text-align: center;
    font-family: 'Heebo';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    /* 192% */
    text-transform: capitalize;
}

.section-4-heading-2 {
    color: #131377;
    text-align: center;
    font-family: 'Heebo';
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 54px;
    /* 108% */
    text-transform: capitalize;
}

.home-section-4-text-block {
    bottom: 0;
    color: #fff;
    font-size: 24px;
    padding: 20px;
    position: absolute;
}

.home-section-4-text-block p {
    color: #FFF;

    font-family: 'Heebo';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    /* 261.905% */
    letter-spacing: 0.3px;
}

.home-section-4-text-block h4 {
    color: #FFF;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.64px;
}


.home-section-4-text-block h6 {
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0.3px;
    line-height: 32px;
    top: 0;
}

.section-4-mobile-card {
    height: 253px;
}

.section-4-blog {
    color: #FFFFFF;
    background-color: #131377;
}

.home-section-5-container {
    position: relative;
}

.home-section-5-text-block {
    position: absolute;
    top: 233px;
    left: 115px;
    padding-left: 20px;
    padding-right: 20px;
    color: #ffffff;
    font-size: 84px;
    font-weight: 800;
    line-height: 87px;
    max-width: 500px;
}

.home-section-5-text-block h4 {
    color: #ffffff;
    font-size: 25px;
    font-weight: 400;
    line-height: 29px;
}

.home-section-5 {
    height: 882px;
}

@media (max-width: 767px) {
    .home-section-5 {
        height: auto;
    }
}

@media (max-width: 767px) {
    .section-5-align {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .section-5-align {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 100%;
        border-radius: 0px;
    }
}

.contact {
    margin-bottom: -150px;
    position: relative;
    z-index: 2;
    background-image: url('../../assets/img/ContactUs.png');

    background-size: 100% 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    /* background-size: cover; */

    background-repeat: no-repeat;
    background-position: center center;
    padding: 3rem !important;
    padding-inline: 6rem !important;
}

@media (max-width: 767px) {
    .contact {
        border-radius: 0px;
        /* padding-inline: 0px !important;
        padding-top: 3rem;
        padding-bottom: 3rem !important; */
        padding: 1rem 0px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .contact {
        border-radius: 0px;
        padding-inline: 0px !important;
    }
}

.sectionContact-title {
    color: #FFF;
    text-align: center;
    font-family: 'Manrope';
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    line-height: 59px;
    letter-spacing: 0.3px;

}

@media (max-width: 767px) {
    .sectionContact-title {
        color: #FFF;

        text-align: center;
        font-family: 'Manrope';
        font-size: 33px;
        font-style: normal;
        font-weight: 800;
        line-height: 38px;
        /* 115.152% */
        letter-spacing: 0.3px;
        padding-top: 1rem;
    }
}

.sectionContact-title-bold {
    font-weight: 500;
}



.back-to-top {
    cursor: pointer;
    position: fixed;
    display: none;
    z-index: 1000;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background: rgba(2, 168, 197, 0.8);
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
}


























/* Contact Us */

.contact-section-1-container {
    position: relative;
}

.contact-section-1-image {
    height: 882px;
}

@media (max-width: 767px) {
    .contact-section-1-image {
        height: auto;
    }
}

.contact-section-1-text-block {
    position: absolute;
    top: 233px;
    color: #ffffff;
    font-size: 67px;
    font-weight: 800;
    line-height: 87px;
    max-width: 450px;
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .contact-section-1-text-block {
        top: 100px;
    }
}

.contact-section-1-text-block p {
    color: #131377;

    font-family: 'Poppins';
    font-size: 76px;
    font-style: normal;
    font-weight: 800;
    line-height: 79px;
    /* 103.947% */
}

.contact-section-1-text-block h4 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 400;
    line-height: 29px;
}



.contact-section-1-text-block-mob {
    position: absolute;
    top: 18px;
    color: #ffffff;
    font-size: 35px;
    font-weight: 800;
    max-width: 500px;
}

.contact-section-1-text-block-mob p {
    color: #131377;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.contact-section-1-text-block-mob h4 {
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-transform: capitalize;
}




.contact-section-2-wrapper {
    background-color: #EDF4F4;
    border-radius: 0px;
    border: none;
    height: 100%;
}

.contact-section-2-address {
    padding: 73px;
    height: 100%;
}

.contact-section-2-address h5 {
    color: #000;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.contact-section-2-address h6 {
    color: #000;

    font-family: 'Poppins';
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    text-transform: capitalize;
}

.contact-section-2-address h3 {
    color: #000000;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 21px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 38px;
    top: 0;
}

.contact-info {
    align-items: center;
}

.contact-detail {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.contact-detail-text {
    color: #000;
    text-decoration: none;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    padding-left: 1rem;
}

.contact-detail img {
    margin-right: 10px;
}

.img-fluid {
    width: 100%;
    height: 100%;
}

/* For mobile devices */
@media (max-width: 767px) {
    .img-fluid {
        height: 100%;
    }

    .contact-section-2-address {
        padding: 25px;
    }
}

/* For iPads */
@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .img-fluid {
        height: 100%;
        width: 100%;
    }

    .contact-section-2-address {
        padding: 25px;
    }

    .contact-section-2-wrapper {
        height: 100%;
    }
}

.contact-section-3-container {
    padding-top: 1.5rem;
    padding-bottom: 5vh;
}

.contact-section-3-container h1 {
    color: #000;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    text-transform: capitalize;
}

.contact-section-3-container p {
    color: #000000;
    font-family: "Manrope-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 27px;
    top: 0;
}



/* .contact-section-3-container button {
    background-color: #06D2ED;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border: none;
}

.contact-section-3-container button:hover {
    background-color: rgba(6, 210, 237, 0.7);
    box-shadow: 0 0 10px rgba(6, 210, 237, 0.7);
}


 */






















/* About Us */
.about-us-section-1-container {
    position: relative;
}

@media (max-width: 767px) {
    .about-us-section-1-image {
        height: auto;
    }
}

.about-us-section-1-text-block {
    position: absolute;
    top: 233px;
    color: #ffffff;
    font-size: 67px;
    font-weight: 800;
    line-height: 87px;
    max-width: 450px;
}



.about-us-section-1-text-block p {
    color: #FFF;
    font-size: 76px;
    font-style: normal;
    font-weight: 800;
    line-height: 79px;
}

.about-us-section-1-text-block h4 {
    color: #FFF;
    font-family: Manrope;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    text-transform: capitalize;
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .about-us-section-1-text-block {
        position: absolute;
        top: 30px;

    }

    .about-us-section-1-text-block p {
        font-size: 55px;
        font-weight: 700;
        line-height: 79px;
    }
}

.about-us-section-1-text-block-mob {
    position: absolute;
    top: 18px;
    color: #ffffff;
    font-size: 35px;
    font-weight: 800;
    max-width: 500px;
}

.about-us-section-1-text-block-mob p {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
}

.about-us-section-1-text-block-mob h4 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-transform: capitalize;
}

.about-us-section-2-container {
    position: relative;
}

.about-us-section-2-container {
    margin-bottom: 3%;
    margin-top: 3%;
}

.about-us-section-2-img-web {
    width: 100%;
}

.about-us-section-2-img-ipad {
    width: 100%;
}

.about-us-section-2-p-ipad {
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.about-us-section-2-container-ipad p {
    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.about-us-section-2-container-ipad h2 {
    color: #00009a;
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
}

.about-us-section-2-container-ipad h1 {
    color: #131377;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.about-us-section-2-container-ipad h3 {
    color: #131377;
    font-family: Heebo;
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
}

.about-us-section-2-container-ipad h2 {
    color: #000;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    text-transform: capitalize;
}

.about-us-section-2-container-ipad h4 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-transform: capitalize;
}


.about-us-section-2-img-mob {
    width: 100%;
}

.about-us-section-2-container-mob {
    position: relative;
}

.about-us-section-2-container-mob {
    margin-bottom: 3%;
    margin-top: 3%;
}

.about-us-section-2-container-mob p {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
}

.about-us-section-2-container-mob h2 {
    color: #00009a;
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
}

.about-us-section-2-container-mob h1 {
    color: #131377;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    text-transform: capitalize;
}

.about-us-section-2-container-mob h3 {
    color: #131377;
    font-family: Heebo;
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
}

.about-us-section-2-container-mob p {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.about-us-section-2-container-mob h2 {
    color: #000;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    text-transform: capitalize;
}

.about-us-section-2-container-mob h4 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-transform: capitalize;
}

.about-us-section-2-container p {
    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.about-us-section-2-container h2 {
    color: #00009a;
    font-size: 15px;
    font-weight: 700;
    line-height: 28px;
}

.about-us-section-2-container h1 {
    color: #131377;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    text-transform: capitalize;
}

.about-us-section-2-container h3 {
    color: #131377;
    font-family: Heebo;
    font-size: 52px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
}

.about-us-section-2-container h2 {
    color: #000;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    text-transform: capitalize;
}

.about-us-section-2-container h4 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-transform: capitalize;
}

.about-us-section-2-card {
    background-color: #EDF4F4;
}

.about-us-section-2-wrapper-cards:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background-color: rgb(101 101 101 / 10%);
    border: none;
}

.about-us-section-2-wrapper-cards {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 1s;

}

.about-us-section-3-wrapper {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 1s;
    padding-inline: 1rem;
}

.about-us-section-3-wrapper:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background-color: rgb(101 101 101 / 10%);
    border: none;
}


.about-us-section-3-container {
    background-image: url('../../assets/img/pg-about-us-sec-3-bg.png');
    padding: 3rem;
    background-size: cover;
}

.card-pic-our-team {
    border: solid 2px #FFFFFF24;
    border-radius: 10px;

}

.card-pic-our-team-img {
    padding: 40px 70px 15px 70px;
}

.card-container {
    border: 1px solid #000;
    margin-bottom: 15px;
}


@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .about-us-section-3-wrapper {
        padding-inline: 10px;
    }

    .card-pic-our-team-img {
        padding: 20px 20px;
    }

}



.about-us-section-3-container h1 {
    color: #FFF;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    text-transform: capitalize;
    font-family: 'Heebo';

}

.about-us-section-3-container h2 {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    text-transform: capitalize;
    font-family: 'Heebo';

}

.about-us-section-3-container h3 {
    color: #FFF;

    text-align: center;
    font-family: 'Heebo';
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 176% */
    text-transform: capitalize;
}

.about-us-section-3-container h4 {
    color: #FFF;

    text-align: center;
    font-family: 'Heebo';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    /* 258.824% */
    text-transform: capitalize;
}

.about-us-section-4-wrapper:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background-color: rgb(101 101 101 / 10%);
    border: none;
}

.about-us-section-4-wrapper {
    border-radius: 10px;
    overflow: hidden;
    transition: transform 1s;
}

.about-us-section-4-container h1 {
    padding-bottom: 2.5rem;
    color: #131377;
    text-align: center;
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 39px;
    text-transform: capitalize;
}

.section-4-company-logo {
    width: -webkit-fill-available;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}


.about-us-section-5-heading-1 {
    color: #000;

    text-align: center;
    font-family: 'Heebo';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    /* 192% */
    text-transform: capitalize;
}

.about-us-section-5-heading-2 {
    color: #131377;

    text-align: center;
    font-family: 'Heebo';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    /* 108% */
    text-transform: capitalize;
}

.about-us-section-5-card {
    border-color: #D6EFEF;
    transition: transform 1s;
    height: 100%;
    box-shadow: 0px 4px 64px 0px rgba(19, 19, 119, 0.14);
}

.about-us-section-5-card:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background-color: rgb(101 101 101 / 10%);
}

.about-us-section-5-card .card-body {
    padding: 1.5rem;
}

.about-us-section-5-card-title {
    color: #000;

    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: -0.36px;
}

.about-us-section-5-card-text {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 143.75% */
    text-transform: capitalize;
}

.about-us-section-5-all-cards .readMoreBtn {
    color: #000;
    font-size: 16px;
    font-family: 'Manrope';

    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.3px;
    border: none;
    background-color: transparent;
}























/* productDevelopment.js */

.prodect-devlopment-section-1-container {
    position: relative;
}

.prodect-devlopment-section-1-image {
    height: 882px;
}

.prodect-devlopment-section-1-text-block {
    position: absolute;
    top: 233px;
    color: #ffffff;
    font-size: 67px;
    font-weight: 800;
    line-height: 87px;
    max-width: 450px;
}

.prodect-devlopment-section-1-text-block p {
    color: #FFF;

    font-family: 'Poppins';
    font-size: 76px;
    font-style: normal;
    font-weight: 800;
    line-height: 79px;
    /* 103.947% */
}

.prodect-devlopment-section-1-text-block h4 {
    color: #FFF;

    font-family: 'Manrope';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    /* 160.714% */
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .prodect-devlopment-section-1-image {
        height: auto;
    }

    .prodect-devlopment-section-1-text-block {
        position: absolute;
        top: 50px;
        font-size: 50px;
        font-weight: 700;
        line-height: 87px;
        max-width: 450px;
    }

    .prodect-devlopment-section-1-text-block p {
        color: #FFF;

        font-family: 'Poppins';
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 39px;
        /* 108.333% */
    }

    .prodect-devlopment-section-1-text-block h4 {
        color: #FFF;

        font-family: 'Manrope';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 152.941% */
        text-transform: capitalize;
    }
}


@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .prodect-devlopment-section-1-text-block {
        position: absolute;
        top: 50px;
        font-size: 50px;
        font-weight: 700;
        line-height: 87px;
        max-width: 450px;
    }

    .prodect-devlopment-section-1-text-block p {
        color: #FFF;

        font-family: 'Poppins';
        font-size: 74px;
        font-style: normal;
        font-weight: 800;
        line-height: 87px;
        /* 117.568% */
    }

    .prodect-devlopment-section-1-text-block h4 {
        color: #FFF;

        font-family: 'Manrope';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 43px;
        /* 153.571% */
        text-transform: capitalize;
    }
}

.prodect-devlopment-section-2-heading {
    color: #131377;
    text-align: center;
    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.product-card {
    background-color: #EDF4F4;
    padding: 2rem;
    height: 100%;
}

.product-card .title {
    color: #000;
    margin: 1rem auto;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    /* 150% */
    text-transform: capitalize;
}

.product-card ul {
    margin-bottom: 0px;
}

.product-card ul>li {
    color: #000;

    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 160% */
    text-transform: capitalize;
}



.prodect-devlopment-section-3-container {
    background-image: url('../../assets/img/product-development/section-3-bg.png');
    background-size: cover;
}

.custom-tab-btn {
    background-color: rgba(0, 0, 0, 0.28);
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    padding: 1rem 2rem;
    fill: rgba(0, 0, 0, 0.28);
    stroke-width: 1px;
    stroke: rgba(255, 255, 255, 0.55);
    margin-inline: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.55);
}

.custom-tab-btn:hover {
    background-color: #00B8D1;
}

.custom-tab-btn.active-btn {
    background-color: #00B8D1;
    color: #FFF;

    font-family: 'Helvetica';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    /* 113.333% */
    border: none;
}

.custom-title-3 {
    color: #FFF;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.custom-title-6 {
    color: #FFF;

    font-family: 'Heebo';
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    /* 192% */
    text-transform: capitalize;
}

.custom-title-10 {
    margin-top: 1.5rem;
    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.custom-desc-6 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.custom-title-2 {
    color: #131377;

    font-family: 'Heebo';
    font-size: 37px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 118.919% */
    text-transform: capitalize;
}

.product-dev-sec-3-wrapper {
    background-color: white;
}



.product-dev-text {
    padding: 4.5rem;
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
    .product-dev-text .custom-title-2 {
        color: #131377;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 25px;
        text-transform: capitalize;
    }

    .product-dev-text .custom-title-6 {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-transform: capitalize;
    }

    .pd-sec-3-img {
        height: 100%;
    }

    .product-dev-sec-3-wrapper {
        display: flex;
    }

    .product-card {
        padding: 1rem;
    }
}
























.about-us-section-5-container {
    background-color: #F5F5F5;
}




/* Blogs */

.blog-item-card {
    border-radius: 15px;
    background: #F6F6FF;
    padding: 1.5rem;
    height: 100%;
}

.blog-item-card:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.blog-item-card .blog-item-img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.blog-item-card .title {
    color: #000;
    margin: 1rem auto;
    font-family: 'Heebo';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    /* 113.636% */
    text-transform: capitalize;
}

.blog-item-card .blog-item-card-desc {
    color: #000;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
}




.heading-blog-page {
    color: #131377;

    font-family: 'Heebo';
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    /* 108% */
    text-transform: capitalize;
}

.blog-page-conslusion h1 {
    color: #000;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.blog-page-conslusion p {
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}

.blog-page-conslusion h3 {
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    text-transform: capitalize;
}

.blog-page-conslusion h4 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    text-transform: capitalize;
}

.blog-page-section-1 h1 {
    color: #000;

    font-family: 'Poppins';
    font-size: 37px;
    font-style: normal;
    font-weight: 800;
    line-height: 49px;
}

.blog-page-section-1 h2 {
    color: #000;
    font-family: 'Manrope';
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 100% */
    text-transform: capitalize;
}

.blog-page-section-1 h3 {
    color: #000;

    font-family: 'Heebo';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px;
    /* 110% */
    text-transform: capitalize;
}

.blog-page-section-1 h4 {
    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}



























/* Blog Page 2 */

.blog-item-2 .title {
    margin: 1rem auto;
    color: #000;

    font-family: 'Heebo';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    /* 113.636% */
    text-transform: capitalize;
}

.blog-item-2 .desc {


    color: #000;

    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    /* 181.25% */
    text-transform: capitalize;
}























































/* CustomButton.js */
.custom-button {
    display: inline-flex;
    border: 2px solid #BFC0C0;
    color: black;
    text-decoration: none;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #00B8D1;
    border-radius: 8px;
    border: none;
    padding: 0px 20px;
    line-height: 25px;
    width: 160px;
    height: 50px;
    font-size: 15px;
}

.arrow-hover {
    /* width: 15px; */
    /* height: 10px; */
    position: absolute;
    transform: translateX(60px);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.14, .59, 1, 1.01);
    transition: all .15s cubic-bezier(.14, .59, 1, 1.01);
    margin: 0;
    padding: 0 5px;
}

button.button-1:hover i {
    width: 15px;
    opacity: 1;
    transform: translateX(50px);
}
















.custom-nav-link {
    font-family: "Roboto";
}


/* .social-button-top-left {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    top: 40%;
    left: calc(100% - 1320px - 5%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background: #131377;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
}

.sticky-social {
    position: fixed;
    top: 30%;
    left: -40px;
    padding: 0px;
    margin: 0px;
}

.social {
    list-style: none;
}

.social li {
    padding: 10px 20px;
    font-size: 25px;
    transition: all 0.8s ease-in-out;
}

.social li:hover {
    margin-right: -30px;
    box-shadow: 2px 5px 10px grey;
}

.social li:hover .fa {
    margin-left: 20px;

}

.fa,
.fa-brands {
    color: #fff;
    transition: all 0.8s ease-in-out;
}

.fb {
    background-color: #3C5A98;
}

.twitter {
    background-color: #000000;
}

.insta {
    background-color: #0a66c2;
} */







.social-button-top-left {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    top: 40%;
    left: calc(100% - 1320px - 5%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background: #131377;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
}


.sticky-social {
    position: fixed;
    top: 30%;
    left: -40px;
    padding: 0px;
    margin: 0px;
    margin-left: 1rem;
}

.social {
    list-style: none;
}

.social li {
    cursor: pointer;
    z-index: 1000;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    background: #131377;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;

    margin-bottom: 1rem;
    margin-left: 1rem;
}

.social li:hover {
    box-shadow: 2px 5px 10px grey;
}

.social li:hover .fa {
    margin-left: 20px;

}

.fa,
.fa-brands {
    color: #fff;
    transition: all 0.8s ease-in-out;
}

@media (max-width: 767px) {
    .sticky-social {
        display: none;
    }
}